/**
 * Mobile Navigation
 */
( function() {
  const navToggle    = document.querySelector('#navtoggle');
  const navParent    = document.querySelector('.nav');
  const navMenuItems = document.querySelectorAll('#navmenu li');

  function init() {
    bindEvents();
  }

  function bindEvents() {
    navToggle.addEventListener('click', toggleNavMenu);

    // No using forEach; thanks IE11
    for (var i = 0; i < navMenuItems.length; i++) {
      navMenuItems[i].addEventListener('click', toggleNavMenu);
    }
  }

  function toggleNavMenu() {
    const isExpanded = navToggle.getAttribute('aria-expanded') === 'true' | false;

    navToggle.setAttribute('aria-expanded', !isExpanded);
    navParent.classList.toggle('open');
  }

  return init();
} )();
